<template>
	<form @submit.prevent="handleSubmit(!v$.$invalid)" class="p-grid">
		<Toast />

		<div class="grid p-fluid">
			<div class="col-12">
				<div class="card card-w-title">
					<Menubar :model="nestedMenuitems">
						<template #end>
							<div class="flex justify-content-end flex-wrap card-container green-container">
								<div class="flex align-items-center justify-content-center font-bold text-gray-500 text-xl m-2">
									{{ mainData.firmaAdi }}
								</div>
								<div class="flex align-items-center justify-content-center m-2">
									<Button class="p-button-rounded p-button-danger mr-0 mb-0" v-if="$router.options.history.state.back != null" icon="pi pi-times" @click="$router.go(-1)"/>
								</div>
							</div>
						</template>
					</Menubar>
				</div>
			</div>
		</div>

		<Message severity="warn" v-if="false">Kapanmamış servis talebi bulunmaktadır.</Message>
		<Message severity="info" v-if="false">Kapanmamış teklifi bulunmaktadır.</Message>

		<div class="grid">
			<div class="col-7">
				<div class="card">
					<EntityHeader label="FİRMA" entityName="account"></EntityHeader>
					<div class="grid">
						<div class="col-12">
							<div class="field p-fluid">
								<label for="firmaadi">Firma Adı <span style="color:red">*</span></label>
								<InputText id="firmaadi" type="text" v-model="mainData.firmaAdi" />
								<span v-if="v$.mainData.firmaAdi.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.firmaAdi.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>
						<div class="col-4">
							<div class="field p-fluid">
								<label for="telefon1">Telefon 1 <span style="color:red">*</span></label>
								<InputMask id="telefon1" v-model="mainData.telefon1" mask="(999) 999-9999" />
								<span v-if="v$.mainData.telefon1.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.telefon1.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>
						<div class="col-4">
							<div class="field p-fluid">
								<label for="telefon2">Telefon 2</label>
								<InputMask id="telefon2" v-model="mainData.telefon2" mask="(999) 999-9999" />
							</div>
						</div>
						<div class="col-4">
							<div class="field p-fluid">
								<label for="websitesi">Web Sitesi</label>
								<InputText id="websitesi" type="text" v-model="mainData.webSitesi" />
							</div>
						</div>
						
						<div class="col-4">
							<div class="field p-fluid">
								<label for="eposta">E-posta Adresi</label>
								<InputText id="eposta" type="text" v-model="mainData.epostaAdresi1" />
							</div>
						</div>
						<div class="col-4">
							<div class="field p-fluid">
								<label for="faks">Faks</label>
								<InputMask id="telefon2" v-model="mainData.fax" mask="(999) 999-9999" />
							</div>
						</div>
						<div class="col-4">
							<div class="field p-fluid">
								<label for="bultenGonderilecekmi">Bülten Gönderilecek mi?</label><br/>
								<InputSwitch id="bultenGonderilecekmi" v-model="mainData.bultenGonderilecekmi" />
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="col-5">
				<div class="card">
					<h5>İLGİLİ KİŞİLER</h5>
					<DataTable
						:value="ilgiliKisilerData"
						scrollable
						scrollHeight="340px"
						selectionMode="single"
						:rowHover="true"
						@rowSelect="onRowSelectIlgiliKisi($event)"
						responsiveLayout="scroll">
						<template #empty>
							Kayıt Bulunamadı.
						</template>
						<template #loading>
							Veriler yükleniyor, lütfen bekleyiniz...
						</template>
						<Column field="tamAdi" header="Tam Adı"></Column>
						<Column field="unvan" header="Ünvan"></Column>
					</DataTable>
				</div>
			</div>
		</div>
		<br/>
		<Accordion>
			<AccordionTab>
				<template #header>
					<span style="font-size: 1.25rem; font-weight: 500;">MÜŞTERİ BİLGİLERİ</span>
				</template>
				
				<div class="grid pt-3">
					<div class="col-3">
						<div class="field p-fluid">
							<label for="firmaadi">Bölge <span style="color:red">*</span></label>
							<Dropdown id="talepturu" v-model="mainData.bolgeId" :options="SM_Bolge" optionLabel="Value" optionValue="AttributeValue" placeholder="Bölge" :showClear="true" :disabled="isDisabled" />
							<span v-if="v$.mainData.bolgeId.$error && submitted">
								<span id="email-error" v-for="(error, index) of v$.mainData.bolgeId.$errors" :key="index">
									<small class="p-error">{{error.$message}}</small>
								</span>
							</span>
						</div>
					</div>
					<div class="col-3">
						<div class="field p-fluid">
							<label for="calisanSayisi">Çalışan Sayısı</label>
							<InputNumber id="calisanSayisi" v-model="mainData.calisanSayisi" mode="decimal" locale="tr-TR" :minFractionDigits="0" showButtons :min="0" :max="15000" :disabled="isDisabled" />
						</div>
					</div>
					<div class="col-3">
						<div class="field p-fluid">
							<label for="firmatipi">Firma Tipi</label>
							<Dropdown id="firmatipi" v-model="mainData.firmaTipiId" :options="SM_FirmaTipi" optionLabel="Value" optionValue="AttributeValue" placeholder="Firma Tipi" :showClear="true" :filter="true" :disabled="isDisabled" />
						</div>
					</div>
					<div class="col-3">
						<div class="field p-fluid">
							<label for="kategori">Kategori</label>
							<Dropdown id="kategori" v-model="mainData.kategoriId" :options="SM_Kategori" optionLabel="Value" optionValue="AttributeValue" placeholder="Kategori" :showClear="true" :disabled="isDisabled" />
						</div>
					</div>
					<div class="col-3">
						<div class="field p-fluid">
							<label for="ilgilimarka">İlgili Marka</label>
							<Dropdown id="ilgilimarka" v-model="mainData.ilgiliMarkaId" :options="SM_IlgiliMarka" optionLabel="Value" optionValue="AttributeValue" placeholder="İlgili Marka" :showClear="true" :filter="true" :disabled="isDisabled" />
						</div>
					</div>
					<div class="col-3">
						<div class="field p-fluid">
							<label for="firmaadi">Kullandığı Tezgah</label>
							<Dropdown id="ilgilimarka" v-model="mainData.kullandigiTezgahId" :options="SM_KullandigiTezgah" optionLabel="Value" optionValue="AttributeValue" placeholder="Kullandığı Tezgah" :showClear="true" :filter="true" :disabled="isDisabled" />
						</div>
					</div>
					<div class="col-3">
						<div class="field p-fluid">
							<label for="firmaadi">Endüstri</label>
							<Dropdown id="ilgilimarka" v-model="mainData.endustriId" :options="SM_Endustri" optionLabel="Value" optionValue="AttributeValue" placeholder="Endüstri" :showClear="true" :filter="true" :disabled="isDisabled" />
						</div>
					</div>
					<div class="col-3">
						<div class="field p-fluid">
							<label for="potansiyel3d">3D Potansiyeli</label>
							<Dropdown id="potansiyel3d" v-model="mainData.potansiyel3d" :options="SM_3dPotansiyeli" optionLabel="Value" optionValue="AttributeValue" placeholder="3D Potansiyeli" :showClear="true" :disabled="isDisabled" />
						</div>
					</div>
				</div>
			</AccordionTab>
		</Accordion>
		<br/>
		<Accordion :activeIndex="0">
			<AccordionTab>
				<template #header>
					<span style="font-size: 1.25rem; font-weight: 500;">ADRES BİLGİLERİ</span>
				</template>
				
				<div class="grid pt-3">
					<div class="col-3">
						<div class="field p-fluid">
							<label for="adresAdi">Adres Adı</label>
							<InputText id="adresAdi" type="text" v-model="mainData.adresAdi" :disabled="isDisabled" />
						</div>
					</div>
					<div class="col-9">
						<div class="field p-fluid">
							<label for="cadde1">Cadde</label>
							<InputText id="cadde1" type="text" v-model="mainData.cadde1" :disabled="isDisabled" />
						</div>
					</div>
					<div class="col-3">
						<div class="field p-fluid">
							<label for="semtbolge">Semt/Bölge</label>
							<InputText id="semtbolge" type="text" v-model="mainData.semtBolge" :disabled="isDisabled" />
						</div>
					</div>
					<div class="col-3">
						<div class="field p-fluid">
							<label for="sehir">Şehir <span style="color:red">*</span></label>
							<Dropdown id="sehir" v-model="mainData.sehirId" :options="SM_Sehir" optionLabel="Value" optionValue="AttributeValue" placeholder="Şehir" :showClear="true" :filter="true" :disabled="isDisabled" />
							<span v-if="v$.mainData.sehirId.$error && submitted">
								<span id="email-error" v-for="(error, index) of v$.mainData.sehirId.$errors" :key="index">
									<small class="p-error">{{error.$message}}</small>
								</span>
							</span>
						</div>
					</div>
					<div class="col-3">
						<div class="field p-fluid">
							<label for="ulke">Ülke</label>
							<Dropdown id="ulke" v-model="mainData.ulkeId" :options="SM_Ulke" optionLabel="Value" optionValue="AttributeValue" placeholder="Ülke" :showClear="true" :disabled="isDisabled" />
						</div>
					</div>
					<div class="col-3">
						<div class="field p-fluid">
							<label for="firmaadi">Posta Kodu</label>
							<InputText id="firmaadi" type="text" v-model="mainData.postaKodu" :disabled="isDisabled" />
						</div>
					</div>
					<div class="col-3" v-if="mainData.sehirId == 34">
						<div class="field p-fluid">
							<label for="istanbulandavr">İstanbul Anadolu Avrupa</label>
							<Dropdown id="istanbulandavr" v-model="mainData.istanbulAndAvrId" :options="SM_IstanbulAnadoluAvrupa" optionLabel="Value" optionValue="AttributeValue" placeholder="İstanbul Anadolu Avrupa" :showClear="true" :disabled="isDisabled" />
						</div>
					</div>
				</div>
			</AccordionTab>
		</Accordion>
		<br/>
		<Accordion>
			<AccordionTab>
				<template #header>
					<span style="font-size: 1.25rem; font-weight: 500;">ADRES BİLGİLERİ 2</span>
				</template>
				
				<div class="grid pt-3">
					<div class="col-3">
						<div class="field p-fluid">
							<label for="adr2_adresAdi">Adres 2: Ad</label>
							<InputText id="adr2_adresAdi" type="text" v-model="mainData.adres2_adresAdi" :disabled="isDisabled" />
						</div>
					</div>
					<div class="col-9">
						<div class="field p-fluid">
							<label for="adr2_cadde1">Adres 2: Cadde 1</label>
							<InputText id="adr2_cadde1" type="text" v-model="mainData.adres2_cadde1" :disabled="isDisabled" />
						</div>
					</div>
					<div class="col-3">
						<div class="field p-fluid">
							<label for="adr2_ilce">Adres 2: İlçe</label>
							<InputText id="adr2_ilce" type="text" v-model="mainData.adres2_ilce" :disabled="isDisabled" />
						</div>
					</div>
					<div class="col-3">
						<div class="field p-fluid">
							<label for="adr2_sehir">Adres 2: Şehir</label>
							<Dropdown id="adr2_sehir" v-model="mainData.sehir2Id" :options="SM_Sehir2" optionLabel="Value" optionValue="AttributeValue" placeholder="Şehir" :showClear="true" :filter="true" :disabled="isDisabled" />
						</div>
					</div>
					<div class="col-3">
						<div class="field p-fluid">
							<label for="adr2_telefon1">Adres 2: Telefon 1</label>
							<InputText id="adr2_telefon1" type="text" v-model="mainData.adres2_telefon1" :disabled="isDisabled" />
						</div>
					</div>
					<div class="col-3">
						<div class="field p-fluid">
							<label for="adr2_telefon2">Adres 2: Telefon 2</label>
							<InputText id="adr2_telefon2" type="text" v-model="mainData.adres2_telefon2" :disabled="isDisabled" />
						</div>
					</div>
					<div class="col-3">
						<div class="field p-fluid">
							<label for="adr2_fax">Adres 2: Faks</label>
							<InputText id="adr2_fax" type="text" v-model="mainData.adres2_fax" :disabled="isDisabled" />
						</div>
					</div>
					<div class="col-3">
						<div class="field p-fluid">
							<label for="adr2_postaKodu">Adres 2: Posta Kodu</label>
							<InputText id="adr2_postaKodu" type="text" v-model="mainData.adres2_postaKodu" :disabled="isDisabled" />
						</div>
					</div>
					
				</div>
			</AccordionTab>
		</Accordion>
		<br/>
		<Accordion>
			<AccordionTab>
				<template #header>
					<span style="font-size: 1.25rem; font-weight: 500;">EK BİLGİLER ve NOTLAR</span>
				</template>
				
				<div class="grid pt-3">
					<div class="col">
						<div class="field p-fluid">
							<label for="firmaadi">Vergi Dairesi</label>
							<InputText id="firmaadi" type="text" v-model="mainData.vergiDairesi" :disabled="isDisabled" />
						</div>
					</div>
					<div class="col">
						<div class="field p-fluid">
							<label for="firmaadi">Vergi Numarası</label>
							<InputText id="firmaadi" type="text" v-model="mainData.vergiNumarasi" :disabled="isDisabled" />
						</div>
					</div>
					<div class="col">
						<div class="field p-fluid">
							<label for="paymenttermscode">Ödeme Koşulları</label>
							<Dropdown id="paymenttermscode" v-model="mainData.odemeKosullariId" :options="SM_paymenttermscode" optionLabel="Value" optionValue="AttributeValue" placeholder="Ödeme Koşulları" :showClear="true" />
						</div>
					</div>
					<div class="col">
						<div class="field p-fluid">
							<label for="firmaadi">Kullandığı Yağ</label>
							<Dropdown id="talepturu" v-model="mainData.kullandigiYagId" :options="SM_KullandigiYag" optionLabel="Value" optionValue="AttributeValue" placeholder="Kullandığı Yağ" :showClear="true" :disabled="isDisabled" />
						</div>
					</div>
					<div class="col">
						<div class="field p-fluid">
							<label for="firmaadi">Yağ Modeli</label>
							<InputText id="firmaadi" type="text" v-model="mainData.yagModeli" :disabled="isDisabled" />
						</div>
					</div>
				</div>
				<div class="grid pt-3">
					<div class="col-12">
						<div class="field p-fluid">
							<label for="firmaadi">Açıklama</label>
							<Textarea id="firmaadi" rows="3" :autoResize="false" v-model="mainData.aciklama" :disabled="isDisabled" />
						</div>
					</div>

					<div class="col-12">
						<div class="field p-fluid">
							<Annotation objectTypeCode="1" logicalName="account" :objectId="entityId" ></Annotation>
						</div>
					</div>
				</div>
			</AccordionTab>
		</Accordion>
		<br/>

		<div v-if="false" class="grid">
			<div class="col-12">
				<div class="card">
					<h5>Tezgahlar</h5>
					<Carousel :value="products" :numVisible="4" :numScroll="3" :circular="false" :responsiveOptions="carouselResponsiveOptions">
						<template #item="product">
							<div class="product-item">
								<div class="product-item-content">
									<div class="mb-5">
										<Image :src="'demo/images/product/' + product.data.image" :alt="product.data.name" width="200" preview />
									</div>
									<div>
										<h6 class="mb-1">
											{{ product.data.name }}
										</h6>
										<div class="grid">
											<div class="col-6">
												<b>PO No:</b> FLO563
											</div>
											<div class="col-6">
												<b>Seri No:</b> 263521
											</div>
										</div>
										
										
										<span :class="'product-badge status-' + product.data.inventoryStatus.toLowerCase()">KURULDU</span>
										<div class="car-buttons mt-5">
											<Button type="button" class="p-button p-button-rounded mr-2" icon="pi pi-search"></Button>
											<Button type="button" class="p-button-success p-button-rounded mr-2" icon="pi pi-star-fill"></Button>
											<Button type="button" class="p-button-help p-button-rounded" icon="pi pi-cog"></Button>
										</div>
									</div>
								</div>
							</div>
						</template>
					</Carousel>
				</div>
			</div>
		</div>
	</form>
</template>

<script>
import CrmService from "../service/CrmService";
import { useVuelidate } from "@vuelidate/core";
import { email, helpers, required } from "@vuelidate/validators";

export default {
	setup: () => ({ v$: useVuelidate() }),
	data() {
		return {
			crmService: null,
			submitted: false,
			OBJECT_TYPE_CODE: 1,
			mainData: {},
			ozetData: {},
			ilgiliKisilerData: [],

			nestedMenuitems: [
				{
					label:'Kaydet',
					icon:'pi pi-fw pi-save',
					command: () => { this.OnSave(); },
				},
			],

			products: [],
            carouselResponsiveOptions: [
                {
                    breakpoint: "1024px",
                    numVisible: 3,
                    numScroll: 3,
                },
                {
                    breakpoint: "768px",
                    numVisible: 2,
                    numScroll: 2,
                },
                {
                    breakpoint: "560px",
                    numVisible: 1,
                    numScroll: 1,
                },
            ],
		}
	},
	async created() {
		this.crmService = new CrmService();
		console.log('created');
		this.mainData["ulkeId"] = 90; //Türkiye
		this.mainData["firmaTipiId"] = 8; //Müşteri
    },
	computed: {
		entityId() {
			return this.$route.params.id;
		},
		profileData(){
			return this.$store.getters.getProfile;
		},
		SM_Bolge: function() {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'bm_bolge');
		},
		SM_FirmaTipi: function() {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'customertypecode');
		},
		SM_IlgiliMarka: function() {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'bm_kullanicitipi');
		},
		SM_Kategori: function() {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'accountcategorycode');
		},
		SM_Endustri: function() {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'industrycode');
		},
		SM_KullandigiTezgah: function() {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'bm_kullandigitezgah');
		},
		SM_Ulke: function() {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'bm_ulke');
		},
		SM_Sehir: function() {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'bm_sehir');
		},
		SM_KullandigiYag: function() {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'bm_kullandigiyag');
		},
		SM_3dPotansiyeli: function() {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'bm_3dpotansiyeli', 'bool');
		},
		SM_IstanbulAnadoluAvrupa: function() {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'bm_istanbulandavr');
		},
		SM_paymenttermscode: function() {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'paymenttermscode');
		},
	},
	methods: {
		async OnSave() {
			debugger;

			if (this.mainData.telefon1 != null && this.mainData.telefon1.slice(0,2) == '(0') {
				this.$toast.add({ severity: 'error', summary: 'Telefon Numarası Hatası', detail: 'Lütfen Telefon 1 numarasını başında 0 olmadan yazınız!', life: 3500 });
				return false;
			}

			if (this.mainData.telefon2 != null && this.mainData.telefon2.slice(0,2) == '(0') {
				this.$toast.add({ severity: 'error', summary: 'Telefon Numarası Hatası', detail: 'Lütfen Telefon 2 numarasını başında 0 olmadan yazınız!', life: 3500 });
				return false;
			}

			if (this.mainData.fax != null && this.mainData.fax.slice(0,2) == '(0') {
				this.$toast.add({ severity: 'error', summary: 'Faks Numarası Hatası', detail: 'Lütfen Faks numarasını başında 0 olmadan yazınız!', life: 3500 });
				return false;
			}

			this.submitted = true;
			this.v$.$touch();

			if (this.v$.$invalid) {
				this.v$.$errors.forEach(element => {
					this.$toast.add({ severity: 'error', summary: 'Eksik alanlar var.', detail: element.$message, life: 3500 });
				});
			} else {
				try {
					const response = await this.crmService.getFirmaCreate(this.mainData);
					if (response) {
						if (response.hata == true) {
							this.$toast.add({severity:'error', summary: 'Kaydedilemedi', detail: response.hataAciklamasi, life: 5000});
						}else {
							this.$toast.add({severity:'success', summary: 'Bilgi', detail:'Başarıyla Kaydedildi', life: 3000});
							if (window.opener) {
								window.opener.window.postMessage({'tag': 'refresh'}, window.location.origin);
							}
							setTimeout(() => {
								this.$router.push({ name: 'account', params: { id: response.olusturulanId } });
							}, 2000);
						}
					}
				} catch (error) {
					this.$toast.add({severity:'error', summary: 'Eksik alanlar var.', detail: error.message, life: 3500});
				}
			}
		},
	},
	watch: {
		profileData(val) {
			if (val) {
				if (val.moduller) {
					const filtered = val.moduller.filter(x => x.name == 'Firmalar');
					if (filtered.length == 0) {
						this.$router.replace({ name: 'accessdenied' });
					}
				}
			}
		}
	},
	validations() {
		return {
			mainData: {
				firmaAdi: {
					required : helpers.withMessage('Firma Adı alanı dolu olmalıdır!', required),
				},
				telefon1: {
					required : helpers.withMessage('Telefon alanı dolu olmalıdır!', required),
				},
				epostaAdresi1: {
					email : helpers.withMessage('Geçerli bir mail adresi giriniz!', email),
				},
				bolgeId: {
					required : helpers.withMessage('Bölge alanı dolu olmalıdır!', required),
				},
				sehirId: {
					required : helpers.withMessage('Şehir alanı dolu olmalıdır!', required),
				},
			},
		}
	}
}
</script>

<style lang="scss" scoped>
.product-item {
    .product-item-content {
        border: 1px solid var(--surface-d);
        border-radius: 3px;
        margin: 0.3rem;
        text-align: center;
        padding: 2rem 0;
    }
    .product-image {
        height: 150px;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    }
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;
	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}
	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}
	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}
</style>
